import React from "react"
import Layout from '../layouts/layout'
import ScrollDown from '../components/scrollDown'
import DynamicWindows from "../components/dynamicWindows"
import Animation from '../components/animation'

import MainPhoneAnimation from '../assets/animations/main_phones.json'
import MainWindowAnimation from '../assets/animations/main_window.json'

import exampleImg from '../assets/images/example.png'
import phone1Img from '../assets/images/phone1.png'
import phone2Img from '../assets/images/phone2.png'
import phone3Img from '../assets/images/phone3.png'
import phone4Animation from '../assets/animations/phone1.json'
import phone5Animation from '../assets/animations/phone2.json'
import videoPoster from '../assets/images/video_placeholder.jpg'

import cloud1Img from '../assets/images/cloud1.png'
import cloud2Img from '../assets/images/cloud2.png'

import experienceVideo from '../assets/videos/experience.webm'
import trailerVideo from '../assets/videos/trailer.mp4'

export default function Home() {
  return <Layout>
    <section className="section section-main">
      <div className="section-main__bg-static" />
      {/* <Animation animation={MainWindowAnimation} autoplay className="section-main__bg-animation" /> */}
      <div className="container">
        <div className="section-main__content">
          <div className="section-main__title">
            Experience Lightwellness
          </div>
          <div className="section-main__subtitle">
          eyrise® dynamic glazing encourage the feeling of <span style={{ whiteSpace: 'nowrap'}}>well-being</span> within the blink of an eye by maximizing the full benefits of natural light.
          </div>
          <a href="#video" className="button section-main__button">Watch the movie</a>
        </div>
        <Animation animation={MainPhoneAnimation} autoplay className="section-main__phones-animation"/>
        <ScrollDown />
      </div>
    </section>
    <section className="section section-about" id="about">
      <div className="section-header section-header--center section-header--align-center">
        <div className="section-header__label">EYrise Glazing</div>
        <div className="section-header__title">Liquid Crystal Leader</div>
        <div className="section-header__subtitle">From the production facility in Europe, the eyrise® team brings Merck’s historical expertise in liquid crystals to the architectural world.</div>
      </div>
      <div className="container">
        <div className="section-about__windows">
          <DynamicWindows />
        </div>
      </div>
    </section>
    <section className="section section--darker section-product" id="product">
      <div className="container">
        <div className="section-header section-product__header">
          <div className="section-header__label">THE EYRISE APP</div>
          <div className="section-header__title">The eyrise® Experience at Your Fingertips</div>
          <div className="section-header__subtitle">Control your dynamic glazing and configure amazing natural animations with just a tap in your eyrise® app – available for eyrise® users.</div>
        </div>
        <div className="section-product__phones-wrapper">
          <div className="section-product__phones-column">
          <img src={phone1Img} alt="" width="345" className="phone1"/>
          <Animation animation={phone4Animation} className="phone4" autoplay style={{width: '345px'}} />
          <img src={phone2Img} alt="" width="345" className="phone2 hide-from-s" />
          </div>
          <div className="section-product__phones-column">
          <Animation animation={phone5Animation} classname="phone5" autoplay style={{width: '345px'}} />
          <img src={phone3Img} alt="" width="345" className="phone3" />


          </div>
          <div className="section-product__phones-column hide-to-s">
            <img src={phone2Img} alt="" width="345" className="phone2" />

          </div>
        </div>
      </div>
    </section>
    <section className="section section-features" id="features">
      <div className="container">
        <div className="section-header section-header--align-center">
          <div className="section-header__label">REAL WORLD EXAMPLES</div>
          <div className="section-header__title">Experience eyrise® Dynamic Glazing</div>
          <div className="section-header__subtitle">You can take a look at eyrise® dynamic glazing already at many locations around the world. Contact us for your nearest location.</div>
        </div> 

        <img src={exampleImg} alt="" className="section-features__image" />
      </div>
    </section>
    <section className="section section-video" id="video">
      <div className="container">
        <div className="section-header section-header--center section-header--align-center">
          <div className="section-header__label">Market leading innovation</div>
          <div className="section-header__title">eyrise® Dynamic Glazing Are Already in Use</div>
        </div> 
        <video src={trailerVideo} style={{ width: '100%' }} autoplay="true" muted="true" loop className="section-video__video" poster={videoPoster} /> 
      </div> 
    </section>
    <section className="section section-experience">
      <img src={cloud1Img} alt="" className="section-experience__cloud1" />
      <img src={cloud2Img} alt="" className="section-experience__cloud2" />
      <video src={experienceVideo} autoplay="true" muted="true" loop className="section-experience__video-bg" />
      <div className="container">
        <div className="section-header section-experience__header">
          <div className="section-header__label">IMMERSING WEATHER EXPERIENCES</div>
          <div className="section-header__title">Adapt Your Light Experience to the Weather</div>
          <div className="section-header__subtitle">Light and Weather are inseparapably connected with each other. With the eyrise® app this connection is made tangible.</div>
        </div>
      </div>  
      
    </section>
    <section className="section section-simulator">
    <div className="container">
        <div className="section-header section-header--center section-header--align-center">
          <div className="section-header__label">REMOTE EXPERIENCE</div>
          <div className="section-header__title">Experience How eyrise® Works</div>
          <div className="section-header__subtitle">Even in globally challenging times, you can see how eyrise® dynamic glazing work and control them on your own, right now.</div>
        </div>
        <iframe title="" src="//re.eyrise.uxsd.io/master/embed/" style={{ width: '100%', height: '719px'}} /> 
      </div>  
    </section>
    {/* <section className="section section--purple section-contact" id="contact">
    <div className="container">
        <div className="section-header section-header--bright section-header--center section-header--align-center">
          <div className="section-header__label">TALK TO US</div>
          <div className="section-header__title">Do You Have Any Open Questions?</div>
        </div>
        <a href="mailto:" className="button button--bright">Get in touch</a>
      </div>  
    </section>  */}
    </Layout>
}
