import React from 'react'
import DoubleArrow from '../../assets/images/doubleArrow.inline.svg'

import './scrollDown.sass'

const ScrollDown = () => {
    return (
        <button className="scroll-down">
            <DoubleArrow />
        </button>
    )
}

export default ScrollDown