import React from "react"

import DribbbleIcon from "../../assets/images/social_dribbble.inline.svg"
import BehanceIcon from "../../assets/images/social_behance.inline.svg"
import MediumIcon from "../../assets/images/social_medium.inline.svg"
import InstagramIcon from "../../assets/images/social_instagram.inline.svg"

import "./footer.sass"

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <nav className="nav footer__social">
        <ul>
          <li>
            {" "}
            <a
              href="https://dribbble.com/uxsd"
              target="_blank"
              rel="noreferrer"
            >
              <DribbbleIcon />
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://www.behance.net/uxsd"
              target="_blank"
              rel="noreferrer"
            >
              <BehanceIcon />
            </a>
          </li>
          <li>
            {" "}
            <a href="https://medium.com/@uxsd" target="_blank" rel="noreferrer">
              <MediumIcon />
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://www.instagram.com/uxstrategydesign/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
          </li>
        </ul>
      </nav>
      <nav className="nav footer__nav">
        <ul>
          <li>
            <a href="https://uxsd.io" target="_blank" rel="noreferrer noopener">
              UX Strategy & Design
            </a>
          </li>
          <li>
            <a
              href="https://uxsd.io/privacy"
              target="_blank"
              rel="noreferrer noopener"
            >
              Data privacy
            </a>
          </li>
          <li>
            <a
              href="https://uxsd.io/imprint"
              target="_blank"
              rel="noreferrer noopener"
            >
              Imprint
            </a>
          </li>
        </ul>
      </nav>
      <div className="footer__copy">© Merck KGaA, Darmstadt, Germany, 2021</div>
    </div>
  </footer>
)

export default Footer
