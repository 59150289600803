import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import DynamicWindow from "../dynamicWindow"

import SolarCrystalOn from '../../assets/images/solar_crystal_on.inline.svg'
import SolarCrystalOff from '../../assets/images/solar_crystal_off.inline.svg'
import PrivacyCrystalOn from '../../assets/images/privacy_crystal_on.inline.svg'
import PrivacyCrystalOff from '../../assets/images/privacy_crystal_off.inline.svg'

// import privacyOn from '../assets/images/privacy_on.png'
// import privacyOff from '../assets/images/privacy_off.png'
// import solarOn from '../assets/images/solar_on.png'
// import solarOff from '../assets/images/solar_off.png'


const DynamicWindows = () => {
    const data = useStaticQuery(graphql`
    query {
      solarOn: file(relativePath: { eq: "images/solar_on.png" }) {
        childImageSharp {
          fluid(maxWidth: 370, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      solarOff: file(relativePath: { eq: "images/solar_off.png" }) {
        childImageSharp {
          fluid(maxWidth: 370, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      privacyOn: file(relativePath: { eq: "images/privacy_on.png" }) {
        childImageSharp {
          fluid(maxWidth: 370, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      privacyOff: file(relativePath: { eq: "images/privacy_off.png" }) {
        childImageSharp {
          fluid(maxWidth: 370, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
      <>
      <div className="section-about__window">
          <div className="section-about__window-title">Solar Glazing</div>
          <DynamicWindow
                  name='eyrise® s350'
                  title='Solar Shading Glass – eyrise® s350'
                  alt='Reduce energy costs, minimize maintenance, and increase usable floor space by choosing eyrise® s350 technology.'
                  activeWindow={data.solarOn.childImageSharp.fluid}
                  activeCrystal={<SolarCrystalOn />}
                  inactiveWindow={data.solarOff.childImageSharp.fluid}
                  inactiveCrystal={<SolarCrystalOff />}
                />
          </div>
          <div className="section-about__window">
              <div className="section-about__window-title">Privacy Glazing</div>
              <DynamicWindow
                  name='eyrise® i350'
                  title='Blinding Privacy Glass – eyrise® i350'
                  alt='Enjoy instant confidentiality, at the touch of a button, wherever you are and whenever you need it with the eyrise® i350.'
                  activeWindow={data.privacyOn.childImageSharp.fluid}
                  activeCrystal={<PrivacyCrystalOn />}
                  inactiveWindow={data.privacyOff.childImageSharp.fluid}
                  inactiveCrystal={<PrivacyCrystalOff />}
                  reverseImagesDirection
                  shieldIcon
                />
          </div>
      </>
  )
}

export default DynamicWindows