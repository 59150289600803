import React, { useEffect, createRef } from 'react'

import lottie from 'lottie-web'

const Animations = ({
  animation,
  containerRef,
  style = {},
  loop = true,
  autoplay = false,
  className = '',
}) => {
  const animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop,
      autoplay,
      animationData: animation,
    })
    if (!autoplay) {
      containerRef.current.addEventListener('mouseenter', function () {
        anim.play()
      })
      containerRef.current.addEventListener('mouseleave', function () {
        anim.stop()
      })
    }
    return () => {
      anim.destroy() // optional clean up for unmounting
    }
  }, [])

  return <div style={{ ...style }} ref={animationContainer} className={className} />
}

export default Animations
