import React, { useState, useCallback } from 'react'

import CrossIcon from '../../assets/images/cross.inline.svg'
import MenuIcon from '../../assets/images/menu.inline.svg'

import './menu.sass'

const Menu = ({ children, className = '' }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const toggleMenu = useCallback(() => {
      setMenuIsOpen(state => !state);  
    }, [setMenuIsOpen]);

    return (
        <div className={`menu ${className} ${menuIsOpen ? 'menu--open' : ''}`}>
            <div className="menu__toggle" onClick={toggleMenu}>{menuIsOpen ? <CrossIcon /> : <MenuIcon /> }</div>
            <div className="menu__content">
                {children}
            </div>
        </div>
    )
}

export default Menu;