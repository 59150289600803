import React from "react"
import Header from '../components/header'
import Footer from '../components/footer'
import "./layout.sass"
import "../styles/index.sass"

const Layout = ({ children }) => {
  return (
    <>
      <div className="layout">
        <Header />
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
