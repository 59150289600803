import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import useAxios from "axios-hooks"
import Logo from "../../assets/images/logo.inline.svg"
import Menu from "../menu"
import "./header.sass"

const Header = () => {
  const [contactEmail, setContactEmail] = useState("uxsd@emdgroup.com")

  const [{ data }] = useAxios(
    "https://5jo58ujmw0.execute-api.eu-central-1.amazonaws.com/production/location"
  )

  useEffect(() => {
    if (!data) {
      return
    }

    if (["US", "CA"].indexOf(data.location.country.iso_code) === -1) {
      setContactEmail("uxsd@merckgroup.com")
    }
  }, [data])

  return (
    <header className="header">
      <div className="container">
        <Link to="/">
          <Logo />
        </Link>
        <Menu className="nav header__nav">
          <ul>
            <li>
              <AnchorLink to="/#about">About Eyrise</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#product">Product</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#features">Features</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#video">Video</AnchorLink>
            </li>
            <li>
              <a href={`mailto:${contactEmail}`}>Contact Us</a>
            </li>
          </ul>
        </Menu>
      </div>
    </header>
  )
}

export default Header
