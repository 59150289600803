import React, { useState, useCallback } from "react"
import Img, { FluidObject } from 'gatsby-image'
import SunIcon from "../../assets/images/sun.inline.svg"
import ShieldIcon from "../../assets/images/shield.inline.svg"
import "./dynamicWindow.sass"

const DynamicWindow = ({
  name,
  title,
  alt,
  activeWindow,
  activeCrystal,
  inactiveWindow,
  inactiveCrystal,
  shieldIcon,
  reverseImagesDirection
}) => {
  const [active, setActive] = useState(true)
  const toggleActive = useCallback(() => {
    setActive(value => !value)
  }, [setActive])

  return (
    <div className="dynamic-window">
      <div
        className="dynamic-window__images-wrapper"
        alignLeft={reverseImagesDirection}
      >
        <div
          className={`dynamic-window__animated ${
            active ? "dynamic-window__animated--active" : ""
          }`}
        >
          <div
            className="dynamic-window__window"
            alignLeft={reverseImagesDirection}
          >
            <Img fluid={activeWindow} title={title} alt={alt} />
          </div>
          {/* <div
            className="dynamic-window__crystal"
            alignLeft={reverseImagesDirection}
          >
            {activeCrystal}
          </div> */}
        </div>
        <div
          className={`dynamic-window__animated ${
            active ? "" : "dynamic-window__animated--active"
          }`}
        >
          <div
            className="dynamic-window__window"
            alignLeft={reverseImagesDirection}
          >
            <Img fluid={inactiveWindow} title={title} alt={alt} />
          </div>
          {/* <div
            className="dynamic-window__crystal"
            alignLeft={reverseImagesDirection}
          >
            {inactiveCrystal}
          </div> */}
        </div>
      </div>
      <div className="dynamic-window__controls">
        <div className="dynamic-window__button-wrapper">
          <a
            className="dynamic-window__button"
            onClick={toggleActive}
          >
            <span className="dynamic-window__toggle-icon">{shieldIcon ? <ShieldIcon /> : <SunIcon />}</span>

            <span>{active ? 'on' : 'off'}</span>
          </a>
        </div>
        <div className="dynamic-window__line">&nbsp;</div>
        <div className="dynamic-window__name">{name}</div>
      </div>
      <div className="dynamic-window__preloader">
        <Img fluid={inactiveWindow} alt={alt} />
        {inactiveCrystal}
      </div>
    </div>
  )
}

export default DynamicWindow
